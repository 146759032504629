<template>
    <div class="row bg-white">
        <div class="col-12">
            <c-search-box>
                <template slot="search">
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <c-plant
                            type="search"
                            name="plantCd"
                            v-model="searchParam.plantCd"
                            @datachange="getLists"
                        />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <c-datepicker
                            required
                            label="년도"
                            type="year"
                            v-model="searchParam.year"
                            @datachange="getLists"
                        />
                    </div>
                </template>
            </c-search-box>
            <div class="row">
                <div class="col-3">
                    <apexchart
                        ref="yearChart"
                        type="bar"
                        height="280"
                        :options="yearChart.chartOptions"
                        :series="yearChart.series"
                    ></apexchart>
                </div>
                <div class="col-9">
                    <apexchart
                        ref="monthChart"
                        type="bar"
                        height="280"
                        :options="monthChart.chartOptions"
                        :series="monthChart.series"
                    ></apexchart>
                </div>
                <div class="col-12">
                    <c-table
                        ref="deptTable"
                        title="부서별 PM 이행율% [PM계획/오더발행]"
                        :merge="grid.merge"
                        gridHeight="420px"
                        :columns="grid.columns"
                        :data="grid.data"
                    >
                    </c-table>
                </div>
            </div>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from "@/js/selectConfig";
import VueApexCharts from "vue-apexcharts";
export default {
    name: "pm-fullfillment-rate",
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            fullscreen: false,
            searchParam: {
                plantCd: "",
                year: "",
            },
            yearChart: {
                chartOptions: {
                    title: {
                        text: "년도별 PM계획/오더발행 건수",
                    },
                    chart: {
                        height: 700,
                        type: "line",
                        dropShadow: {
                            enabled: true,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "60%",
                            endingShape: "rounded",
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                        },
                    },
                    stroke: {
                        width: [2, 2, 2, 2, 2],
                        curve: "straight",
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: "건",
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    grid: {
                        borderColor: "#e7e7e7",
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            sizeOffset: 6,
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return +val + " 건";
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "PM계획",
                        data: [],
                    },
                    {
                        name: "오더발행",
                        data: [],
                    },
                ],
                chartWidth: "80%",
            },
            monthChart: {
                chartOptions: {
                    title: {
                        text: "월별 PM계획/오더발행 건수",
                    },
                    chart: {
                        height: 700,
                        dropShadow: {
                            enabled: true,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "60%",
                            endingShape: "rounded",
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                        },
                    },
                    stroke: {
                        width: [2, 2, 2, 2, 2],
                        curve: "straight",
                    },
                    xaxis: {
                        categories: [
                            "1월",
                            "2월",
                            "3월",
                            "4월",
                            "5월",
                            "6월",
                            "7월",
                            "8월",
                            "9월",
                            "10월",
                            "11월",
                            "12월",
                        ],
                    },
                    yaxis: {
                        title: {
                            text: "건",
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    colors: ["#FEB019", "#FF4560"],
                    grid: {
                        borderColor: "#e7e7e7",
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            sizeOffset: 6,
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return +val + " 건";
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "PM계획",
                        data: [],
                    },
                    {
                        name: "오더발행",
                        data: [],
                    },
                ],
                chartWidth: "80%",
            },
            grid: {
                merge: [],
                columns: [],
                data: [],
            },
            period: [],
            editable: true,
            searchUrl: "",
            popupOptions: {
                isFull: false,
                target: null,
                title: "",
                visible: false,
                width: "80%",
                param: {},
                closeCallback: null,
            },
            deptUrl: "",
            monthUrl: "",
            yearUrl: "",
        };
    },
    watch: {},
    beforeCreate() {},
    created() {},
    beforeMount() {
        Object.assign(this.$data, this.$options.data());
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {},
    methods: {
        init() {
            this.searchParam.year = this.$comm.getThisYear();

            this.yearChart.chartOptions.xaxis.categories.push(
                this.$comm.getPrevYear(-1) + "년"
            );
            this.yearChart.chartOptions.xaxis.categories.push(
                this.$comm.getThisYear() + "년"
            );
            // api setting
            this.deptUrl = selectConfig.sts.pma.pmdept.url;
            this.monthUrl = selectConfig.sts.pma.pmmonth.url;
            this.yearUrl = selectConfig.sts.pma.pmyear.url;

            this.getLists();
            this.setHeader();
        },
        getLists() {
            if (!this.searchParam.year) {
                window.getApp.$emit("ALERT", {
                    title: "안내", // 안내
                    message: "년도를 선택하세요.", // 년도를 선택하세요
                    type: "warning", // success / info / warning / error
                });
                return;
            }
            this.getDeptList();
            this.getYearGraph();
            this.getMonthGraph();
        },
        getDeptList() {
            this.$http.url = this.deptUrl;
            this.$http.type = "GET";
            this.$http.param = this.searchParam;
            this.$http.request((_result) => {
                console.log(_result.data);
                this.grid.data = _result.data;
            });
        },
        getYearGraph() {
            this.$http.url = this.yearUrl;
            this.$http.type = "GET";
            this.$http.param = this.searchParam;
            this.$http.request((_result) => {
                this.$_.forEach(_result.data, (_item) => {
                    this.$set(this.yearChart.series[0], "data", [
                        _item.req1,
                        _item.req2,
                    ]);
                    this.$set(this.yearChart.series[1], "data", [
                        _item.ord1,
                        _item.ord2,
                    ]);
                });

                this.$refs["yearChart"].refresh();
                this.yearChart.chartWidth = "90%";
            });
        },
        getMonthGraph() {
            this.$http.url = this.monthUrl;
            this.$http.type = "GET";
            this.$http.param = this.searchParam;
            this.$http.request((_result) => {
                this.$_.forEach(_result.data, (_item) => {
                    this.$set(this.monthChart.series[0], "data", [
                        _item.req1,
                        _item.req2,
                        _item.req3,
                        _item.req4,
                        _item.req5,
                        _item.req6,
                        _item.req7,
                        _item.req8,
                        _item.req9,
                        _item.req10,
                        _item.req11,
                        _item.req12,
                    ]);
                    this.$set(this.monthChart.series[1], "data", [
                        _item.ord1,
                        _item.ord2,
                        _item.ord3,
                        _item.ord4,
                        _item.ord5,
                        _item.ord6,
                        _item.ord7,
                        _item.ord8,
                        _item.ord9,
                        _item.ord10,
                        _item.ord11,
                        _item.ord12,
                    ]);
                });

                this.$refs["monthChart"].refresh();
                this.monthChart.chartWidth = "90%";
            });
        },
        setHeader() {
            this.grid.columns = [
                {
                    name: "plant_name",
                    field: "plant_name",
                    label: "사업장",
                    align: "center",
                    fix: true,
                    sortable: true,
                    style: "width: 120px",
                },
                {
                    name: "dept_name",
                    field: "dept_name",
                    label: "부서",
                    align: "center",
                    fix: true,
                    sortable: true,
                    style: "width: 120px",
                },
            ];
            for (let i = 1; i <= 12; i++) {
                let monPercent = "";
                if (i < 10) {
                    monPercent = "0" + i;
                } else {
                    monPercent = i;
                }
                this.grid.columns.push({
                    name: "month" + i,
                    field: "month" + i,
                    label: i + "월",
                    child: [
                        {
                            name: "req" + i,
                            field: "req" + i,
                            label: "계획",
                            align: "right",
                            sortable: true,
                            style: "width: 60px",
                        },
                        {
                            name: "ord" + i,
                            field: "ord" + i,
                            label: "발행",
                            align: "right",
                            sortable: true,
                            style: "width: 60px",
                        },
                        {
                            name: monPercent,
                            field: monPercent,
                            label: "백분율",
                            align: "right",
                            sortable: true,
                            style: "width: 70px",
                        },
                    ],
                });
            }
        },
    },
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
    -webkit-animation: blink 1s ease-in-out infinite alternate;
    -moz-animation: blink 1s ease-in-out infinite alternate;
    animation: blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
